<template>
  <div class="pt-10">
    <v-row>
      <v-col cols="12" md="2">
        <v-autocomplete
          class="c-input-small"
          v-model="id_e_market"
          :items="eMarkets"
          :label="$t('labels.e_market')"
          outlined
          dense
          hide-details
          @change="onEMarketChanged"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <select-multiple-model
          v-model="id_shops"
          :label="$t('labels.shop')"
          :options="shopOptions"
          :single-line="false"
          @change="onShopChanged"
        ></select-multiple-model>
      </v-col>
      <v-col cols="12" md="4">
        <v-row>
          <v-col>
            <v-btn
              small
              color="error"
              block
              outlined
              class="mr-1"
              style="height: 40px"
              @click="clearAll"
            >
              {{ $t("labels.clear") }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              small
              color="success"
              block
              outlined
              class="mr-1"
              style="height: 40px"
              @click="generateSku"
            >
              {{ $t("labels.confirm") }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              small
              color="primary"
              block
              outlined
              style="height: 40px"
              :disabled="newCodes.length == 0"
              @click="downloadNewSku"
            >
              {{ $t("labels.redownload") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="8">
        <v-simple-table
          height="calc(100vh - 500px)"
          class="table-padding-2-no-top table-h-36"
        >
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th colspan="9" class="fs-16px primary--text">
                  {{ $t("labels.goods") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="9">
                  <SelectSuggestGoodsNoWarehouse
                    @onSelect="selectItem"
                    :select-items="items"
                  />
                </td>
              </tr>
              <tr class="font-weight-medium text-center">
                <td style="width: 100px">{{ $t("labels.image") }}</td>
                <td>{{ $t("labels.sku") }}</td>
                <td>{{ $t("labels.barcode") }}</td>
                <td>{{ $t("labels.category") }}</td>
                <td>{{ $t("labels.name") }}</td>
                <td>{{ $t("labels.goods_description") }}</td>
                <td>{{ $t("labels.size") }}</td>
                <td style="width: 100px">{{ $t("labels.quantity") }}</td>
                <td class="text-center" style="width: 75px">
                  <v-btn
                    v-if="items.length > 0"
                    color="error"
                    x-small
                    @click="clearItem()"
                  >
                    {{ $t("labels.clear") }}
                  </v-btn>
                </td>
              </tr>
              <tr
                class="text-center"
                v-for="(cItem, cIndex) in items"
                :key="`c_${cIndex}_${cItem.id}`"
              >
                <td>
                  <ImageViewer
                    v-if="cItem.url_images"
                    :url="cItem.url_images"
                    width="40px"
                    height="40px"
                  />
                </td>
                <td>{{ cItem.sku }}</td>
                <td>{{ cItem.customer_goods_barcode }}</td>
                <td>{{ cItem.category_name }}</td>
                <td>{{ cItem.name }}</td>
                <td>{{ cItem.description }}</td>
                <td>{{ cItem.size }}</td>
                <td>
                  <input-number
                    v-model.number="cItem.quantity"
                    type="number"
                    outlined
                    dense
                    hide-details
                    class="c-input-small"
                  />
                </td>
                <td>
                  <v-btn
                    x-small
                    color="error"
                    @click="removeItem(cItem)"
                    class="mb-1"
                  >
                    {{ $t("labels.delete") }}
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-col cols="12" md="4">
        <v-simple-table
          height="calc(100vh - 500px)"
          class="table-padding-2-no-top table-h-36"
        >
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th colspan="9" class="fs-16px primary--text">
                  {{ $t("labels.combo") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="9">
                  <SelectSuggestComboNoWarehouse
                    @onSelect="selectCombo"
                    :select-items="combos"
                  />
                </td>
              </tr>
              <tr class="font-weight-medium text-center">
                <td>{{ $t("labels.barcode") }}</td>
                <td>{{ $t("labels.name") }}</td>
                <td style="width: 100px">{{ $t("labels.quantity") }}</td>
                <td class="text-center" style="width: 75px">
                  <v-btn
                    v-if="combos.length > 0"
                    color="error"
                    x-small
                    @click="clearCombo()"
                  >
                    {{ $t("labels.clear") }}
                  </v-btn>
                </td>
              </tr>
              <tr
                class="text-center"
                v-for="(cItem, cIndex) in combos"
                :key="`c_${cIndex}_${cItem.id}`"
              >
                <td>{{ cItem.barcode }}</td>
                <td>{{ cItem.name }}</td>
                <td>
                  <input-number
                    v-model.number="cItem.quantity"
                    type="number"
                    outlined
                    dense
                    hide-details
                    class="c-input-small"
                  />
                </td>
                <td>
                  <v-btn
                    x-small
                    color="error"
                    @click="removeCombo(cItem)"
                    class="mb-1"
                  >
                    {{ $t("labels.delete") }}
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { E_MARKETS } from "@/libs/const";
import { httpClient } from "@/libs/http";
// import { debounce } from "lodash/function";

export default {
  name: "EMarketGenerateSku",
  components: {
    SelectSuggestGoodsNoWarehouse: () =>
      import("@/components/common/SelectSuggestGoodsNoWarehouse"),
    SelectSuggestComboNoWarehouse: () =>
      import("@/components/common/SelectSuggestComboNoWarehouse"),
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  props: {},
  data: () => ({
    filters: {},
    shops: [],
    eMarkets: [...E_MARKETS],
    items: [],
    combos: [],
    id_e_market: null,
    id_shops: [],
    newCodes: [],
  }),
  computed: {
    shopOptions() {
      if (!this.id_e_market) {
        return [];
      }
      return [...this.shops]
        .filter((shop) => shop.id_e_marketplace == this.id_e_market)
        .map((shop) => ({
          text: shop.display_name,
          value: shop.id,
        }));
    },
  },
  watch: {},
  mounted() {
    this.getShop();
  },
  methods: {
    onEMarketChanged() {
      this.id_shops = [];
      this.newCodes = [];
    },

    onShopChanged() {
      this.items = [];
      this.combos = [];
      this.newCodes = [];
    },

    selectItem(item) {
      this.items.push({
        ...item,
        quantity: 1,
      });
      this.newCodes = [];
    },

    removeItem(item) {
      const items = [...this.items].filter((it) => it.id !== item.id);
      this.items = [...items];
      this.newCodes = [];
    },

    clearItem() {
      const items = [];
      this.items = [...items];
      this.newCodes = [];
    },

    selectCombo(item) {
      this.combos.push({
        ...item,
        quantity: 1,
      });
      this.newCodes = [];
    },

    removeCombo(item) {
      const combos = [...this.combos].filter((it) => it.id !== item.id);
      this.combos = [...combos];
      this.newCodes = [];
    },

    clearCombo() {
      const combos = [];
      this.combos = [...combos];
      this.newCodes = [];
    },

    clearAll() {
      this.id_e_market = null;
      this.newCodes = [];
      this.combos = [];
      this.items = [];
      this.id_shops = [];
    },

    async getShop() {
      try {
        const { data } = await httpClient.post("/get-all-e-market-shop", {
          active: 1,
        });
        this.shops = [...data];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    async generateSku() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/goods-e-market-generate-sku", {
          id_e_market: this.id_e_market,
          id_shops: this.id_shops,
          items: this.items,
          combos: this.combos,
        });
        this.newCodes = [...data];
        this.downloadNewSku();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async downloadNewSku() {
      const filename = "ds-hang-hoa-va-sl-kha-dung.xlsx";

      try {
        await this.downloadExcelFile(
          "/goods-export-sku-quantity",
          {
            id_e_market_shops: this.id_shops,
            new_codes: this.newCodes,
            type: 5,
          },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
